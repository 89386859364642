<template>
  <div v-if="userInfo" class="bg_view">
    <van-nav-bar left-text="返回 | 下级业绩" left-arrow fixed placeholder @click-left="onClickLeft">
      <template #right>
        <div class="menu_view" @click="showMyJuniorPicker = true">
          <span>{{ screenUser.name ? `已选 | ${screenUser.name}` : "所有下级" }}</span>
          <van-icon name="arrow" />
        </div>
      </template>
    </van-nav-bar>
    <van-popup v-model="showMyJuniorPicker" round position="bottom">
      <van-picker show-toolbar :columns="userInfo.myJuniorList" value-key="name" cancel-button-text="重置" @cancel="resetChooseAction" @confirm="chooseMyJuniorAction" />
    </van-popup>
    <!-- 筛选栏 -->
    <div class="screen_menu_view">
      <template v-for="(item, index) in ['今日', '昨日', '当月', '上月', '']">
        <div class="item_view" :key="`key_${index}`" :class="`${index == screenTimeType ? 'is_active' : ''}`" @click="screenClick(index)">
          <span>{{ index == 4 ? getScreenChooseTimeText : item }}</span>
        </div>
      </template>
      <div class="empty_view"></div>
    </div>
    <div class="hide_scroll_bar"></div>
    <!-- 日期选择 -->
    <van-calendar :allow-same-day="true" :default-date="calendarDefaultTime" :lazy-render="true" :min-date="calendarMinTime" v-model="showScreenCalendar" type="range" @confirm="screenCalendarChooseAction" />
    <!-- 列表 -->
    <div class="list_view" v-show="dataList.length > 0">
      <table border="0" cellspacing="0" cellpadding="0">
        <thead class="sticy_top">
          <tr>
            <td :style="`${index != 0 ? 'border-left: none' : ''}`" v-for="(item, index) in listTypes" :key="`key_${index}`" :class="`${index == 0 ? 'sticy_left' : ''}`">
              <div class="list_row_view" style="font-weight: bold;" @click="sortAction(item)">
                <span>{{ item.name }}</span>
                <div class="sort_view" v-if="item.sort">
                  <van-icon name="arrow-up" :class="`${sortType == item.sort[1] ? 'select_icon' : ''}`" />
                  <van-icon name="arrow-down" :class="`${sortType == item.sort[0] ? 'select_icon' : ''}`" />
                </div>
              </div>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in dataList" :key="`key_${index}`">
            <td class="sticy_left" :style="`border-left: 1px solid #E6E6E6;overflow: hidden;max-width:100px`">
              <div class="list_row_view">{{ item.name }}</div>
            </td>
            <td>
              <div class="list_row_view">{{ item.forecastSale }}</div>
            </td>
            <td>
              <div class="list_row_view">{{ item.validSale }}</div>
            </td>
            <td>
              <div class="list_row_view">{{ item.forecastBrokerage }}</div>
            </td>
            <td>
              <div class="list_row_view">{{ item.validBrokerage }}</div>
            </td>
            <td>
              <div class="list_row_view">{{ item.forecastOrderNum }}</div>
            </td>
            <td>
              <div class="list_row_view">{{ item.validOrderNum }}</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="empty-box" v-if="dataList.length == 0">
      <img class="empty-img" :src="require('@/assets/icon_empty.png')" alt="">
      <div class="empty-text">暂无数据</div>
    </div>
    <div class="list_hide_bottom_bar"></div>
  </div>
</template>

<script>
import API from '@/common/axios';
import { Toast } from 'vant';
import moment from 'moment';
import 'moment/locale/zh-cn';
moment.locale('zh');
export default {
  data() {
    return {
      screenUser: {},
      showMyJuniorPicker: false,
      userInfo: null,
      // 0 今日 1昨日 2近7日 3近30日 4自定义时间
      screenTimeType: 0,
      screenChooseTime: {
        start: "",
        end: "",
      },
      calendarMinTime: new Date(moment().subtract(2, 'years').add(1, 'days').valueOf()),
      calendarDefaultTime: [new Date(), new Date()],
      showScreenCalendar: false,
      listTypes: [
        { name: "下级名称" },
        { name: "预估销售额", sort: [1, 2] },
        { name: "有效销售额", sort: [3, 4] },
        { name: "预估佣金", sort: [5, 6] },
        { name: "有效佣金", sort: [7, 8] },
        { name: "预估订单数", sort: [9, 10] },
        { name: "有效订单数", sort: [11, 12] }
      ],
      sortType: 1,
      dataList: []
    };
  },
  computed: {
    getScreenChooseTimeText() {
      if (!this.screenChooseTime.start) {
        return "自定义";
      } else if (this.screenChooseTime.start == this.screenChooseTime.end) {
        return this.screenChooseTime.start;
      } else {
        return `${this.screenChooseTime.start}至${this.screenChooseTime.end}`;
      }
    }
  },
  mounted() {
    this.getUserInfo();
  },
  methods: {
    onClickLeft() {
      this.$router.replace('/propertyManager/home');
    },
    getUserInfo() {
      API({
        url: "/h5/property/getUserInfo.do",
        data: {}
      }).then(res => {
        if (res.success == 1) {
          res.data.myJuniorList = [{ name: "所有下级", phone: "-1" }].concat(res.data.myJuniorList);
          this.userInfo = res.data;
          if (res.data.isManager != 1) {
            this.onClickLeft();
          } else {
            this.getData();
          }
        } else {
          Toast(res.errMsg || "请重试！");
        }
      }).catch(_ => {
        Toast("服务异常，请刷新页面重试！", _);
      });
    },
    getData(isSort) {
      setTimeout(() => {
        let dom = this.$el.getElementsByClassName('list_view')[0];
        dom.scrollTop = 0;
        if (!isSort) {
          dom.scrollLeft = 0;
          this.sortType = 1;
        }
      });
      let params = { screenPhone: this.screenUser.phone || "", sortType: this.sortType };
      if (this.screenTimeType == 0) {
        // 今日
        params.screenStartTime = moment().format('YYYY-MM-DD');
        params.screenEndTime = params.screenStartTime;
      } else if (this.screenTimeType == 1) {
        // 昨日
        params.screenStartTime = moment().subtract(1, 'day').format('YYYY-MM-DD');
        params.screenEndTime = params.screenStartTime;
      } else if (this.screenTimeType == 2) {
        // 当月
        params.screenStartTime = moment().startOf('month').format('YYYY-MM-DD');
        params.screenEndTime = moment().format('YYYY-MM-DD');
      } else if (this.screenTimeType == 3) {
        // 上月
        params.screenStartTime = moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
        params.screenEndTime = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
      } else {
        params.screenStartTime = this.screenChooseTime.start;
        params.screenEndTime = this.screenChooseTime.end;  
      }
      const loading = Toast.loading({
        loadingType: "spinner",
        position: "middle",
        message: "加载中..."
      });
      API({
        url: "/h5/property/querySubordinatePerformance.do",
        data: params
      }).then(res => {
        loading.clear();
        if (res.success == 1) {
          this.dataList = res.data;
        } else {
          this.dataList = [];
          Toast(res.errMsg || "请重试！");
        }
      }).catch(_ => {
        this.dataList = [];
        loading.clear();
        Toast("服务异常，请刷新页面重试！");
      });
    },
    resetChooseAction() {
      this.showMyJuniorPicker = false;
      if (!this.screenUser.name) {
        return;
      }
      this.screenTimeType = 0;
      this.screenUser = {};
      this.getData();
    },
    chooseMyJuniorAction(e) {
      if (e.phone == '-1') {
        this.resetChooseAction();
        return;
      }
      this.showMyJuniorPicker = false;
      if (this.screenUser.name == e.name || this.screenUser.phone == e.phone) {
        return;
      }
      this.screenUser = { ...e };
      this.screenTimeType = 0;
      setTimeout(() => {
        let dom = this.$el.getElementsByClassName('screen_menu_view')[0];
        dom.scrollLeft = 0;
      });
      this.screenChooseTime = { ...this.screenChooseTime, start: "", end: "" };
      this.getData();
    },
    screenClick(index) {
      if (index == 4) {
        this.isShowDownloadCalender = false;
        this.showScreenCalendar = true;
      } else {
        if (this.screenTimeType == index) {
          return;
        }
        this.screenTimeType = index;
        this.screenChooseTime = { ...this.screenChooseTime, start: "", end: "" };
        this.getData();
      }
    },
    screenCalendarChooseAction(e) {
      let [startTime, endTime] = e;
      let now = moment();
      let start = moment(startTime);
      let end = moment(endTime);
      if (start.isAfter(now) || end.isAfter(now)) {
        Toast("所选时间区间不可大于今天");
        return;
      }
      this.screenChooseTime.start = start.format('YYYY-MM-DD');
      this.screenChooseTime.end = end.format('YYYY-MM-DD');
      this.showScreenCalendar = false;
      this.screenTimeType = 4;
      setTimeout(() => {
        let dom = this.$el.getElementsByClassName('screen_menu_view')[0];
        dom.scrollLeft = dom.scrollWidth - dom.clientWidth;
      });
      this.getData();
    },
    sortAction(item) {
      if (!item.sort) {
        return;
      }
      let [one, two] = item.sort;
      if (this.sortType == one) {
        this.sortType = two;
      } else {
        this.sortType = one;
      }
      this.getData(true);
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/ .van-nav-bar {
  .van-icon {
    color: black;
  }

  .van-nav-bar__text {
    color: black;
  }
}

.bg_view {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #fff;
}

.menu_view {
  margin-right: 0;
  margin-left: auto;
  padding: 0 6px 0 10px;
  height: 24px;
  border-radius: 12px;
  border: 1px solid #784CFA;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 13px;
  color: #784CFA;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 160px;

  /deep/ .van-icon {
    color: #784CFA;
  }
}

.screen_menu_view {
  display: flex;
  margin-top: 16px;
  overflow-x: auto;
  white-space: nowrap;
  height: 36px;
  position: relative;
  padding-right: 12px;

  .item_view {
    padding: 0 13px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: #999999;
    line-height: 25px;
    margin-left: 11px;
    height: 25px;
    border: 1px solid #999999;
    border-radius: 13px;
    box-sizing: border-box;
  }

  .is_active {
    border: 1px solid #784CFA;
    background-color: rgba(120, 76, 250, 0.16);
    color: #784CFA;
  }

  .empty_view {
    width: 1px;
    height: 100%;
    flex-shrink: 0;
  }
}

/deep/ {
  .van-calendar__footer {
    padding-bottom: 20px;
  }

  .van-calendar__day--middle {
    color: #784CFA;
  }

  .van-calendar__day--end,
  .van-calendar__day--multiple-middle,
  .van-calendar__day--multiple-selected,
  .van-calendar__day--start,
  .van-calendar__day--start-end {
    background-color: #784CFA;
  }

  .van-button--danger {
    background-color: #784CFA;
    border-color: #784CFA;
  }
}

.hide_scroll_bar {
  position: relative;
  width: 100%;
  height: 8px;
  z-index: 2;
  background-color: #fff;
  margin-top: -8px;
}

.list_view {
  margin: 10px;
  flex: 1;
  overflow: auto;
  position: relative;
  padding-bottom: 10px;

  table {
    table-layout: auto;
    width: auto;
  }

  td {
    border: 1px solid #E6E6E6;
  }

  .list_row_view {
    background-color: #F5F5F7;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 42px;
    padding: 0 10px;
    min-width: 90px;

    .sort_view {
      margin-left: 4px;
      display: flex;
      flex-direction: column;
      align-items: center;

      /deep/ .van-icon {
        color: #999999;
        display: block;
        font-size: 12px;
        font-weight: bold;

        &:last-child {
          margin-top: -4px;
        }
      }

      .select_icon {
        color: #784CFA;
      }
    }

  }

  .sticy_left {
    position: sticky;
    left: 0;
    z-index: 2;
  }

  .sticy_top {
    position: sticky;
    top: 0;
    z-index: 3;
  }

  tbody {
    td {
      border-top: none;
      border-left: none;
    }

    .list_row_view {
      color: #666;
      background-color: white;
    }
  }

}

.list_hide_bottom_bar {
  position: absolute;
  bottom: 0;
  height: 20px;
  background-color: white;
  width: 100%;
  z-index: 4;
}

.empty-box {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;

  .empty-img {
    width: 140px;
  }

  .empty-text {
    text-align: center;
    font-size: 14px;
    color: #666666;
    line-height: 20px;
    margin-top: 4px;
  }
}
</style>